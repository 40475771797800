<script>
	export default {
		name: 'Home',
	
		props: [],
	
		components: {},
	
		data: function() {
			return {
				menuId: ''
			};
		},
	
		created: function() {
			this.init();
		},
	
		computed: {},
	
		methods: {
			init() {
				let menus = this.$athlon.getMenuData();
				if(menus.length > 0){
					this.$router.push({
						path: this.getFirstMenuPath(menus),
						query: {
							menuId: this.menuId
						}
					});
				}else{
					this.$router.push('/error/401');
				}
			},
			getFirstMenuPath(menus){
				let tempPath = '';
				if(menus[0].subMenu.length > 0){
					tempPath = this.getFirstMenuPath(menus[0].subMenu);
				}else{
					if(menus[0].path == '/demo'){
						tempPath = this.getFirstMenuPath(menus[1].subMenu);
					}else{
						tempPath = menus[0].path;
						this.menuId = menus[0].__id;
					}
				}
				return tempPath;
			}
		}
	}
	</script>
	